<template>
  <div class="indicador-view-contenedor">
      <div class="ficha-indicador">
          <Nav/>

          <div class="ficha-titulo">
              <h1>{{ indicador }}</h1>
              <h3> <span>Periodo: </span>{{ periodo }}</h3>
          </div>

        <div class="ficha-indicador-body">

            <div class="ficha-mapa">
                <Mapa 
                @diputaciones="informacionDistrito( $event )"
                name="diputacion"/>
            </div>
            <div class="ficha-grafica">
                <div class="info-inicial" v-if="mostrarGraficaInicial">
                    <Contenedor
                        :showGrafica="showGrafica"
                        dataDescripcion="Proporción de mujeres y hombres electas y electos en Morelos"
                        :dataGrafica="dataGrafica"
                    />
                </div>
                <div class="info-especifica" v-else>
                    <h4>{{ mensajeProblema }}</h4>
                    <h5>{{electa.distrito }}</h5>
                    <h3>{{ electa.nombre }}</h3>
                    <h5>{{ electa.sexo }}</h5>
                    <p>{{ electa.partido }}</p>
                </div>
                
            </div>

        </div>
          
       
      </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'


export default {
    components: {
        Nav: defineAsyncComponent( () => import('../../components/Nav.vue')),
        Mapa: defineAsyncComponent( () => import('../../../../components/SvgIcons.vue')),
        Contenedor: defineAsyncComponent( () => import('../../components/contenedor-indicador.vue'))
    },
    data() {
        return {
            indicador: 'Diputaciones electas ',
            periodo: '2021',
            mostrarGraficaInicial: true,
            showGrafica: {
                pie: false,
                barras: true
            },
            dataGrafica: {
                hombres: 6,
                mujeres: 6,
            },
            dataDescripcion: 'Total de diputaciones electas en los 12 distritos del estado de Morelos',
            electa: {
                nombre: null,
                sexo: null,
                partido: null,
                distrito: null
            },
            mensajeProblema: null
        }
    },
    methods: {
        getData() {

            const url = '/diputaciones/proceso-electoral/nuevo/diputaciones-electas'
            this.axios.get(`${url}`)
                .then( res => {
                    return this.saveData( res.data )
                })
                .catch( e => {
                    console.log( 'error: ' + e );
                }) 
        
        },
        saveData( data ){    
            this.myData = data
            if (data[0].distrito === "Todos") {
                const { hombres, mujeres } = data[0]
                this.dataGrafica.hombres = Number(hombres)
                this.dataGrafica.mujeres = Number(mujeres)
                this.viewOptions.pie = true
                this.dataDescripcion = 'Número de mujeres y hombres inscritas e inscritos en la lista nominal de electores'
            }
        },
        informacionDistrito( data ) {
            console.log( data );
            const filterDistrito = this.myData.filter( e => e.distrito === data.distrito )
            this.mostrarGraficaInicial = false
            if( filterDistrito.length != 0 ) {
                const { nombre, sexo, partido, distrito } = filterDistrito[0]

                this.mensajeProblema = ''
                this.electa.nombre = `${ nombre } `
                this.electa.sexo = `${sexo}`
                this.electa.partido = `${ partido }`
                this.electa.distrito = distrito
            } else{
                this.mensajeProblema = 'Lo sentido, información no disponible'
                this.electa.nombre = ''
                this.electa.sexo = ''
                this.electa.partido = ''
                this.electa.distrito = ''
            }
            
           
        }
    },
    created(){
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

    .indicador-view-contenedor{
        width:80%;
        margin:0 auto;
        height:calc(100vh - 60px);
        background-color: #fff;
        display:flex; align-items:center; justify-content:center;

        .ficha-indicador{
            width:94%;
            margin:10px auto;
            height:calc(100vh - 120px);
            background-color: #f2f2f2;
        }

        .ficha-titulo {
            width:70%;
            height:50px;
            margin:10px auto;
            display: flex; align-items:center; justify-content:space-evenly;
            border-bottom:1px solid black;


            & h1, h3 {
                font-size:22px;
                font-weight: bold;
            }
        }

        .ficha-indicador-body{
                display:flex; align-items:center; justify-content: space-around;
                width:100%;
                height:630px;
                margin:10px auto;

                & .ficha-mapa{
                    width:50%;
                    height: 100%;
                    display:flex; align-items:center; justify-content:center;
                }
                .ficha-grafica{
                    width:50%;
                    height:100%;
                    display:flex; flex-direction:column; align-items:center; justify-content:center; 
                }
            }

       
    }

</style>