<template>
  <div class="indicador-view-contenedor">
      <div class="ficha-indicador">
          <Nav/>
          <Titulo
            :indicador="indicador"
            :periodo="periodo"
            />
            <div class="contenedor-candidatura flex">
                <div class="ficha-partidos">
                    <div class="hombres">

                        <h5>Hombres</h5>
                        <div class="ficha" v-for="(elemento, index) in dataHombres" :key="index">

                            <div class="ficha-contenido">

                                <div class="ficha-nombre">
                                    <p>{{ elemento.nombre }}</p>
                                </div>
                                <div class="ficha-partido">
                                    <p>Partido: {{ elemento.partido }}</p>
                                </div>
                                <div class="ficha-sexo">
                                    <p>{{ elemento.Sexo }}</p>
                                </div>
                            </div>

                        </div>



                    </div>
                    <div class="mujeres">
                        <h5>Mujeres</h5>
                        <div class="ficha" v-for="(elemento, index) in dataMujeres" :key="index">

                            <div class="ficha-contenido">

                                <div class="ficha-nombre">
                                    <p>{{ elemento.nombre }}</p>
                                </div>
                                <div class="ficha-partido">
                                    <p>{{ elemento.partido }}</p>
                                </div>
                                <div class="ficha-sexo">
                                    <p>{{ elemento.Sexo }}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="grafica">
                    <Grafica
                        titulo="Candidaturas"
                        :pie="pie"
                        :hombres="hombres"
                        :mujeres="mujeres"
                    />

                </div>
            </div>

      </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Nav: defineAsyncComponent( () => import('../../components/Nav.vue')),
        Titulo: defineAsyncComponent( () => import('../../components/ficha-titulo.vue')),
        Grafica: defineAsyncComponent( () => import('../../components/Grafica-dona.vue'))
    },
     data() {
        return {
            indicador: 'Candidaturas',
            periodo: '2021',
            hombres: null,
            mujeres: null,
            dataHombres: [],
            dataMujeres: [],
            pie: true
        }
    },
    methods: {
        getData() {

        fetch('../../DB/diputaciones/proceso-electoral/candidaturas-diputaciones.json')
        .then( res => res.json() )
        .then( data => this.saveData( data ))
        },
        saveData( data ){
            const params = this.$router.currentRoute._value.params.distrito
            const resultado = data.filter( item => item.distrito === params )
            const hombres = resultado.filter( item => item.Sexo === 'Hombre')
            const mujeres = resultado.filter( item => item.Sexo === 'Mujer' ) 
            this.hombres = hombres.length
            this.mujeres = mujeres.length
            this.dataHombres = hombres
            this.dataMujeres = mujeres

            
            
            // const { hombres, mujeres } = resultado[0].aspirantes
           
            // this.dataGrafica.hombres = Number(hombres)
            // this.dataGrafica.mujeres = Number(mujeres)
            // this.viewOptions.pie = true
            
        } 
    },
    created(){
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

    .indicador-view-contenedor{
        width:70%;
        margin:0 auto;
        height:calc(100vh - 60px);
        background-color: #fff;
        display:flex; align-items:center; justify-content:center;

        .ficha-indicador{
            width:94%;
            margin:10px auto;
            height:calc(100vh - 120px);
            background-color: #f2f2f2;
        }

        .contenedor-candidatura{
            
            height: calc(100vh - 300px);
            margin:30px auto;
            width:90%;

            .ficha-partidos{
                width:66%;
                height:90%;
                margin:0 auto;
                display: flex;

                .hombres{
                    width:46%;
                    height:95%;
                    margin:10px auto;
                    background-color: #fff;
                    overflow-y: scroll ;
                    border-radius:10px;
                    &::-webkit-scrollbar {
                        width:4px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: grey;
                    }
                    & h5{
                        font-size: 16px;
                        padding:10px 12px;
                    }

                    .ficha{
                        width:94%;
                        height:80px;
                        margin:10px auto;
                        border-radius:10px;
                        display:flex; align-items:center; justify-content: center;
                        flex-direction: column;
                        .ficha-contenido {
                            width:90%;
                            height:85%;
                            background-color: #f1f1f1;
                            margin:10px auto;
                            border-radius:10px;
                            padding:12px 14px;
                            display: flex; align-items: flex-start; justify-content:center; 
                            flex-direction: column;
                            .ficha-nombre{
                                font-size:12px;
                                & p{
                                    margin: 0;
                                }
                            }
                            .ficha-partido{
                                font-size: 12px;
                                & p{
                                    margin: 0;
                                }
                            }
                            .ficha-sexo{
                                font-size:12px;
                                & p{
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .mujeres{
                     width:46%;
                    height:95%;
                    margin:10px auto;
                    background-color: #fff;
                    overflow-y: scroll ;
                    border-radius:10px;
                    &::-webkit-scrollbar {
                        width:4px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background-color: grey;
                    }
                    & h5{
                        font-size: 16px;
                        padding:10px 12px;
                    }
                    .ficha{
                        width:94%;
                        height:80px;
                        margin:10px auto;
                        border-radius:10px;
                        display:flex; align-items:center; justify-content: center;
                        flex-direction: column;
                        .ficha-contenido {
                            width:90%;
                            height:85%;
                            background-color: #f1f1f1;
                            margin:10px auto;
                            border-radius:10px;
                            padding:12px 14px;
                            display: flex; align-items: flex-start; justify-content:center; 
                            flex-direction: column;
                            .ficha-nombre{
                                font-size:12px;
                                & p{
                                    margin: 0;
                                }
                            }
                            .ficha-partido{
                                font-size: 12px;
                                & p{
                                    margin: 0;
                                }
                            }
                            .ficha-sexo{
                                font-size:12px;
                                & p{
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
            .grafica{
                width:30%;
                height:90%;
                margin:0 auto;
                display:flex; align-items:center; justify-content:center;
            }
        }

    }

</style>