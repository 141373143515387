<template>
  <div class="indicadores-contenedor">

      <div class="item-flex-between">
        <h1>Ejercicio del cargo</h1>
        <span
            @click="goMenu"
        >
            X
        </span>
      </div>

      <div class="contenedor-indicadores">
          <div class="ficha-indicador" v-for="(elemento, index) in indicadores" :key="index">
                <div class="ficha-body">
                    <div class="ficha-titulo">
                            <h3>{{elemento.nombre}}</h3>
                             <div class="contenedor-logo">
                                <Iconos :nombre= "elemento.icono" />
                            </div>
                        </div>
                    <div class="ficha-descripcion">
                        <p>{{elemento.descripcion}}</p>
                    </div>
                </div>
                <button
                    @click="goIndicadores( elemento.path )"
                    class="btn-consulta"
                >
                        Consultar
                </button>

          </div>
      </div>
  </div>
</template>



<script>
import Iconos from '../components/iconos/diputaciones/ejercicioCargo.vue'
export default {
    components: {
        Iconos
    },
    data() {
        return {
           indicadores: [
                {
                    nombre: 'Grupos Parlamentarios',
                    descripcion: 'Conformación de Grupos Parlamentarios',
                    path:'diputacion-grupos-parlamentarios',
                    icono: 'Grupo-Parlamentario'
                },
                {
                    nombre: 'Nivel Educativo',
                    descripcion: 'Nivel Educativo de las diputadas y diputados',
                    path:'diputacion-nivel-educativo',
                    icono: 'Nivel-Educativo'
                },
                {
                    nombre: 'Junta de Coordinación Política',
                    descripcion: 'Número de integrantes de la Junta de Coordinación Política',
                    path:'diputacion-junta-coordinacion-politica',
                    icono: 'Junta-Coordinacion'
                },
                {
                    nombre: 'Mesa Directiva',
                    descripcion: 'Integrantes de la Mesa Directiva',
                    path:'diputacion-mesa-directiva',
                    icono: 'Mesa-Directiva'
                },
                {
                    nombre: 'Comisiones',
                    descripcion: 'Número de Comisiones que hay en el Poder Ejecutivo',
                    path:'diputacion-comisiones',
                    icono: 'Comisiones'
                },
                {
                    nombre: 'Asistencias',
                    descripcion: 'Asistencias a las Sesiones',
                    path:'diputacion-asistencias',
                    icono: 'Asistencias'
                },
                {
                    nombre: 'Participación en Tribuna',
                    descripcion: 'Número de Participaciones en Tribuna',
                    path:'diputacion-participacion-tribuna',
                    icono: 'Participacion-Tribuna'
                },
                {
                    nombre: 'Propuestas al Orden del Día',
                    descripcion: 'Subir puntos de acuerdo en el orden del día.  Sesión / Periodo de Sesiones',
                    path:'diputacion-propuestas-orden-dia',
                    icono: 'Propuesta-Orden-Dia'
                },
                {
                    nombre: 'Exhortos',
                    descripcion: 'Presentaciój de exhortos por sesión/ periodo de sesiones',
                    path:'diputacion-exhortos',
                    icono: 'Exhortos'
                },
                {
                    nombre: 'Comparecencias',
                    descripcion: 'Número de solicitudes de comparecencia se han presentado por sesión / periodo de sesiones',
                    path:'diputacion-comparecencias',
                    icono: 'Comparecencias'
                },
                {
                    nombre: 'Iniciativa de Ley',
                    descripcion: 'Número de propuestas presentadas por diputadas / diputados por sesión / periodo de sesiones',
                    path:'diputacion-iniciativas-ley',
                    icono: 'Iniciativa-Ley'
                },
                 {
                    nombre: 'Redes Sociales',
                    descripcion: 'Redes Sociales del Congreso, por sesión / periodo de sesiones',
                    path:'diputacion-redes-sociales',
                    icono: 'Redes-Sociales'
                },
                 {
                    nombre: 'Nombramientos Realizados',
                    descripcion: 'Nombramientos a cargo de la cámara de diputadas/dos. Sesión / periodo de sesiones',
                    path:'diputacion-nombramientos-realizados',
                    icono: 'Nombramientos'
                },
                 {
                    nombre: 'Permisos / Licencias',
                    descripcion: 'Número de permisos y/o licencias que se solicitan',
                    path:'diputacion-permisos-licencias',
                    icono: 'Licencias'
                },
                 {
                    nombre: 'VPRG',
                    descripcion: 'Denuncias presentadas por VPRG',
                    path:'diputacion-violencia-razon-genero',
                    icono: 'Violencia-Genero'
                },
            ]
        }
    },
    methods: {
        goIndicadores( data ) {
            this.$router.push( { name: `${ data }`, params: { distrito: this.$router.currentRoute._value.params.distrito }})
        },
        goMenu() {
            this.$router.push({ name: 'dip-menu-indicadores' })
        }
    
    }
}
</script>

<style lang="scss">
.indicadores-contenedor{
    width:96%;
    margin:20px auto;
    & h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform:uppercase;
    }
}

    .contenedor-indicadores{
        min-width:980px;
        margin:10px auto;
        display:flex; flex-wrap: wrap; align-items:center; justify-content: center;
    }
    .ficha-indicador{
        width:23%;
        height:250px;
        margin:10px 10px;
        background-color: #fff;
        border-radius:10px;
        padding:10px 12px;
        text-align:center;
    }
    .ficha-titulo{
        display:flex; align-items: center; justify-content: space-between;
    }
    .ficha-titulo h3{
        font-size:14px;
        text-align:left;
        width:50%;
        margin:30px auto;
        cursor:pointer;
        font-weight: bold;
    }
    .ficha-titulo h3:hover{
        text-decoration:underline;
    }
    
    .ficha-descripcion{
        width:90%;
        margin:0 auto;
        height: 80px;
        & p{
            font-size:12px;
            text-align:left;
        }
    }
    .contenedor-logo{
        width:40%;
        display:flex; align-items:center; justify-content:center;
        margin:20px auto;

    }
   

</style>