<template>
    <div class="iconos-indicadores">
        <svg v-if="nombre==='ProcesoElectoral'" version="1.1" viewBox="0 0 188.38 224.89" xmlns="http://www.w3.org/2000/svg">
 
                <path class="st0" d="m187.18 87.71c-2.84-4.56-23.89-38.37-25.23-40.49-0.12-0.19-0.24-0.39-0.36-0.6-1.49-2.54-4.27-7.27-13.69-7.27h-33.42l-20.23-35.04c-2.38-4.12-7.66-5.53-11.78-3.16l-31.89 18.41c-4.12 2.38-5.53 7.66-3.15 11.78l4.62 8h-11.58c-9.42 0-12.2 4.73-13.69 7.27-0.12 0.21-0.24 0.41-0.36 0.6-1.34 2.13-22.39 35.94-25.23 40.5-0.75 1.05-1.19 2.34-1.19 3.73v21.87c0 3.57 2.9 6.47 6.47 6.47h2.46v97.58c0 4.15 3.38 7.53 7.53 7.53h155.46c4.15 0 7.53-3.38 7.53-7.53v-97.58h2.46c3.57 0 6.47-2.9 6.47-6.47v-21.87c-0.01-1.39-0.45-2.67-1.2-3.73zm-132.6-61.21 31.89-18.41c0.1-0.06 0.21-0.08 0.31-0.08 0.21 0 0.42 0.11 0.54 0.31l21.97 38.05 0.57 0.98 8.94 15.48h-43.96l-8.94-15.48-2.48-4.29-9.07-15.71c-0.17-0.3-0.07-0.68 0.23-0.85zm-21.4 24.98c0.16-0.26 0.32-0.53 0.49-0.81 1.09-1.85 1.95-3.32 6.79-3.32h16.2l8.94 15.48h-11.5c-2.21 0-4 1.79-4 4s1.79 4 4 4h81.11c2.21 0 4-1.79 4-4s-1.79-4-4-4h-7.33c-0.17-0.47-0.38-0.93-0.63-1.37l-8.15-14.11h28.81c4.84 0 5.7 1.46 6.79 3.32 0.17 0.28 0.33 0.56 0.49 0.81 1.01 1.6 13.79 22.13 20.86 33.49h-163.74c7.08-11.36 19.86-31.88 20.87-33.49zm138.26 165.42h-154.52v-97.11h154.52zm8.93-105.12h-172.38v-18.81h172.38z" fill="#fff"/>
        </svg>
        <svg v-else-if="nombre==='EjercicioCargo'"  version="1.1" viewBox="0 0 199.97 307.31" xmlns="http://www.w3.org/2000/svg">
                <path class="st0" d="m194.94 87.057c2.55-3.274 6.81-8.7608 4.23-14.037-2.13-4.3554-7.74-6.2978-18.18-6.2978h-134.16v-8.4404c0-6.5881 0.01-13.416 17.69-27.464 2.48 1.4818 5.36 2.3429 8.45 2.3429 9.13 0 16.56-7.4392 16.56-16.58s-7.43-16.58-16.56-16.58-16.56 7.4392-16.56 16.58c0 3.1339 0.89 6.0474 2.4 8.5505-18.43 14.858-19.97 23.829-19.97 33.151v8.4404h-19.86c-10.45 0-16.06 1.9424-18.18 6.2978-2.58 5.2765 1.68 10.763 4.23 14.037 3.89 5.0062 27.81 34.963 32.78 41.181v150.88h-4.56c-7.71 0-13.98 6.2777-13.98 13.997v6.7283c0 4.1251 3.35 7.4692 7.46 7.4692h146.51c4.12 0 7.46-3.3541 7.46-7.4692v-6.7283c0-7.7195-6.27-13.997-13.98-13.997h-4.56v-150.88c4.97-6.2277 28.89-36.185 32.78-41.181zm-121.99-79.047c4.72 0 8.56 3.8447 8.56 8.5705s-3.84 8.5705-8.56 8.5705-8.56-3.8447-8.56-8.5705 3.85-8.5705 8.56-8.5705zm-61.61 74.131c-2.74-3.5243-3.34-5.0062-3.33-5.6169 0.31-0.29036 2.32-1.7922 10.97-1.7922h162c8.65 0 10.66 1.5018 10.97 1.7922 0.01 0.61075-0.59 2.0826-3.33 5.6169-2.89 3.7246-17.49 22.027-26.46 33.271v-9.1813c0-5.9173-4.81-10.733-10.72-10.733h-102.91c-5.91 0-10.72 4.8159-10.72 10.733v9.1813c-8.98-11.244-23.58-29.556-26.47-33.271zm155.38 204.97c3.3 0 5.98 2.6833 5.98 5.9874v6.1976h-145.44v-6.1976c0-3.3041 2.68-5.9874 5.98-5.9874h128.91zm-120.92-8.0099v-172.88c0-1.5018 1.22-2.7234 2.72-2.7234h102.91c1.5 0 2.72 1.2215 2.72 2.7234v172.88z" style="stroke-width:1.0006" fill="#fff"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: ['nombre']
}
</script>
