<template>
  <div class="indicadores-contenedor">
       <div class="item-flex-between">
        <h1>Proceso electoral</h1>
        <span
            @click="goMenu"
        >
            X
        </span>
      </div>
      <div class="contenedor-indicadores">
          <div class="ficha-indicador" v-for="(elemento, index) in indicadores" :key="index">
                <div class="ficha-body">
                    <div class="ficha-titulo">
                            <h3>{{elemento.nombre}}</h3>
                            <div class="contenedor-logo">
                                <Iconos :nombre= "elemento.icono" />
                            </div>
                        </div>
                    <div class="ficha-descripcion">
                        <p>{{elemento.descripcion}}</p>
                    </div>
                </div>
                <button 
                    class="btn-consulta"
                    @click="goIndicador(elemento.path)"
                >Consultar</button>

          </div>
      </div>
  </div>
</template>

<script>
import Iconos from '../components/iconos/municipios/procesoElectoral.vue'

export default {
    components: {
        Iconos
    },
    data() {
        return {
           indicadores: [
                {
                    nombre: 'Aspirantes',
                    descripcion: 'Proporción de mujeres en el universo de potenciales aspirantes',
                    path: 'municipio-aspirantes',
                    icono: 'Aspirantes'
                },
                {
                    nombre: 'Lista Nominal',
                    descripcion: 'Proporción de mujeres que se encuentran inscritas en la lista nominal de electores',
                    path:'municipio-lista-nominal',
                    icono:'Lista-Nominal'
                },
                {
                    nombre: 'Postulaciones Internas',
                    descripcion: 'Proporción de mujeres postuladas inicialmente por sectores internos partidiarios',
                    path: 'municipio-postulaciones-internas',
                    icono: 'Postulaciones-Internas'
                },
                {
                    nombre: 'Candidaturas',
                    descripcion: 'Proporción de mujeres candidadas resultantes de elecciones internas partidiarias',
                    path: 'municipio-candidaturas',
                    icono: 'Candidaturas'
                },
                {
                    nombre: 'Bloques de Competitividad',
                    descripcion: 'Diferencias proporcionales entre bloques de competitividad',
                    path: 'municipio-bloques-competitividad',
                    icono:'Bloque-competitividad'
                },
                {
                    nombre: 'Resultados de las elecciones 2021',
                    descripcion: 'Resultados de las personas electas',
                    path: 'municipio-resultados-elecciones',
                    icono:'Resultados'
                },
                {
                    nombre: 'Presidencias Municipales con mayor población',
                    descripcion: 'Proporción de mujeres y hombres en presidencias municipales en municipios de mayor población',
                    path: 'municipio-presidencia-mayor-poblacion',
                    icono:'Municipio-Mayoria'
                },
            ]
        }
    },
    methods: {
        goIndicador( data ) {
            this.$router.push( { name: `${ data }`, params: { municipio: this.$router.currentRoute._value.params.municipio }})
        },
        goMenu() {
            this.$router.push({ name: 'home-municipios' })
        }
    }
}
</script>

<style lang="scss" >
.indicadores-contenedor{
    width:96%;
    margin:20px auto;
    & h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform:uppercase;
    }
}

    .contenedor-indicadores{
        min-width:980px;
        margin:10px auto;
        display:flex; flex-wrap: wrap; align-items:center; justify-content: center;
    }
    .ficha-indicador{
        width:23%;
        height:250px;
        margin:10px 10px;
        background-color: #fff;
        border-radius:10px;
        padding:10px 12px;
        text-align:center;
    }
   .ficha-titulo{
        display:flex; align-items: center; justify-content: space-between;
    }
    .ficha-titulo h3{
        font-size:14px;
        text-align:left;
        width:50%;
        margin:30px auto;
        cursor:pointer;
        font-weight: bold;
    }
    .ficha-titulo h3:hover{
        text-decoration:underline;
    }
    
    .ficha-descripcion{
        width:90%;
        margin:0 auto;
        height: 80px;
        & p{
            font-size:12px;
            text-align:left;
        }
    }
    .contenedor-logo{
        width:40%;
        display:flex; align-items:center; justify-content:center;
        margin:20px auto;

    }
    

</style>