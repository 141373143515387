
<template>
  <div class="menu-indicadores">
    <div class="boton-salir">
      <span 
          @click="backDiputaciones"
          class="close-button"
      > 
          X
      </span>
    </div>
    

   <div class="area-informacion">
     <h1>Indicadores sobre diputaciones </h1>
   </div>

    <div class="indicadores-contenedor">
      <div class="indicador-box">
        <div class="item-box">
          <div class="icon-contenedor">
            <Iconos
              nombre="ProcesoElectoral"  
            />
          </div>
          <p @click="procesoElectoral">Proceso Electoral</p>
        </div>
        <div class="item-box">
           <div class="icon-contenedor">
            <Iconos
              nombre="EjercicioCargo"
            />
          </div>
          <p @click="ejercicioCargo">Ejercicio del Cargo</p>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import Iconos from '@/components/IcoIndicadores.vue'

export default {
  data() {
    return {
      diputacion: {
        distrito: '',
        cabecera: ''
      }
    }
  },

  components: {
    Iconos
  },
  methods: {
    procesoElectoral(){
      this.$router.push({ name: 'diputaciones-proceso-electoral' })
    },
    ejercicioCargo() {
      this.$router.push({ name: 'diputaciones-ejercicio-cargo' })
    },
    backDiputaciones() {
      this.$router.push( { name: 'Home' })
    },
    getData() {

      fetch('../../DB/diputaciones/proceso-electoral/DLocales.json')
        .then( res => res.json() )
        .then( data => this.saveData( data ))

    },
    saveData( data ){
      const params = this.$router.currentRoute._value.params.distrito
      const resultado = data.filter( item => item.distrito === params )
      const { numeroDistrito, cabecera } = resultado[0]
      this.diputacion.distrito = numeroDistrito
      this.diputacion.cabecera = cabecera
      
      
    } 
  },
  created(){
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
  .menu-indicadores{
    width:60%;
    height: calc( 100vh - 100px);
    margin:30px auto;
    background-color: #fff;
    
    & .boton-salir{
      width:90%;
      margin:10px auto;
      text-align:right;
      padding-top: 10px;
    }

    &  .close-button{
      font-weight: bold;
      font-size:30px;
      padding:20px 22px 0 0;
      cursor:pointer;
      color: #392f5c;
      width:80%;

    }
    
    .indicadores-contenedor{
      width:100%;
      height: calc(100vh - 380px);
      display: flex; align-items:center; justify-content:space-around;
    }
    .indicador-box{
      width:90%;
      height:300px;
      display:flex; align-items:center; justify-content:space-between; 
    }

    .item-box{
      width:45%;
      height: 90%;;
      margin:0 auto;
      background-color: #392f5c;
      display:flex; align-items:center; justify-content:center; flex-direction:column;
      color: #fff;
      font-size:22px;
      font-weight: bold;
    }
    & p{
        cursor:pointer;
      }
      & p:hover{
        text-decoration:underline;
      }
      
      .icon-contenedor{
        width:10%;
        margin:20px auto;
      }

      .area-informacion{
        width:85%;
        height: 100px;
        background-color: #f2f1f1;
        margin:20px auto;
        padding:12px 14px;
        display:flex; align-items:center; justify-content:space-around;
        & h1{
          font-size: 18px;
          text-transform: uppercase;

        }

        & p{
          margin: 0;
        }
      }

  }

</style>