<template>
  <div class="indicador-view-contenedor">
    <div class="ficha-indicador">
        <Nav/>
        <Titulo
            :indicador="indicador"
            :periodo="periodo"
        />
        <div class="ficha-indicador-body">

            <div class="ficha-mapa">
                <Mapa name="MapaGeneral"/>
            </div>
            <div class="ficha-grafica">
                 <Contenedor
                    :dataDescripcion="dataDescripcion"
                    :dataGrafica="dataGrafica"
                    :showGrafica="viewOptions"
                />
            </div>

        </div>
      </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Nav: defineAsyncComponent( () => import('../../components/NavMunicipio.vue')),
        Titulo: defineAsyncComponent( () => import('../../components/ficha-titulo.vue')),
        Contenedor: defineAsyncComponent( () => import('../../components/contenedor-indicador.vue')),
        Mapa: defineAsyncComponent( () => import('../../../../components/SvgIcons.vue'))
    },
    data() {
        return {
            indicador: 'Universo Aspirantes',
            periodo: '2021',
            dataDescripcion: null,
            dataGrafica: {
                hombres: null,
                mujeres: null
            },
            viewOptions: {
                pie: null,
                barras: null
            }
        }
    },
     methods: {
        getData() {
            const url = '/municipios/proceso-electoral/nuevo/aspirantes'
           this.axios.get(`${url}`)
            .then( res => {
                return this.saveData( res.data )
            })
            .catch( e => {
                console.log( 'error: ' + e );
            }) 
        
        },
        saveData( data ){    
            if (data[0].municipio === "Todos") {
                const { hombres, mujeres } = data[0]
                this.dataGrafica.hombres = Number(hombres)
                this.dataGrafica.mujeres = Number(mujeres)
                this.viewOptions.pie = true
                this.dataDescripcion = 'Número de mujeres y hombres mayores de 18 años en Morelos'
            }

            return 
        } 
    },
    created(){
        this.getData()
    }
}
</script>

<style lang="scss" >

    .indicador-view-contenedor{
        width:70%;
        margin:0 auto;
        height:calc(100vh - 60px);
        background-color: #fff;
        display:flex; align-items:center; justify-content:center;

        .ficha-indicador{
            width:94%;
            margin:10px auto;
            height:calc(100vh - 120px);
            background-color: #f2f2f2;

            .ficha-indicador-body{
                display:flex; align-items:center; justify-content: space-around;
                width:100%;
                height:630px;
                margin:10px auto;

                & .ficha-mapa{
                    width:50%;
                    height: 100%;
                    display:flex; align-items:center; justify-content:center;
                }
                .ficha-grafica{
                    width:50%;
                    height:100%;
                    display:flex; flex-direction:column; align-items:center; justify-content:center; 
                }
            }
        }
    }

</style>