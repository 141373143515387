<template>
  <div class="indicadores-contenedor">
      
       <div class="item-flex-between">
        <h1>Ejercicio del cargo</h1>
        <span
            @click="goMenu"
        >
            X
        </span>
      </div>


      <div class="contenedor-indicadores">
          <div class="ficha-indicador" v-for="(elemento, index) in indicadores" :key="index">
                <div class="ficha-body">
                    <div class="ficha-titulo">
                           <h3>{{elemento.nombre}}</h3>
                            <div class="contenedor-logo">
                                <Iconos :nombre= "elemento.icono" />
                            </div>
                        </div>
                    <div class="ficha-descripcion">
                        <p>{{elemento.descripcion}}</p>
                    </div>
                </div>
                <button 
                    @click="goIndicadores( elemento.path) "
                    class="btn-consulta"
                >
                        Consultar
                </button>

          </div>
      </div>
  </div>
</template>

<script>
import Iconos from '../components/iconos/municipios/ejercicioCargo.vue'
export default {
    components: {
        Iconos
    },
    data() {
        return {
            indicadores: [
                {
                    nombre: 'Protesta',
                    descripcion: 'Mujeres y hombres rendirán protesta como integrantes de cabildo',
                    path:'municipios-protesta',
                    icono: 'Protesta'
                },
                {
                    nombre: 'Integración de comisiones',
                    descripcion: 'Integración de comisiones',
                    path:'municipios-integracion-comisiones',
                    icono: 'Integración-Comisiones'
                },
                {
                    nombre: 'Sesiones de cabildo',
                    descripcion: 'Realizción de sesiones de cabildo',
                    path:'municipios-sesiones-cabildo',
                    icono: 'Sesiones-Canbildo'
                },
                {
                    nombre: 'Redes Sociales',
                    descripcion: 'Redes Sociales oficiales de los municipios',
                    path:'municipios-redes-sociales',
                    icono: 'Redes-Sociales'
                },
                {
                    nombre: 'Auditorías',
                    descripcion: 'Número de auditorías que se han realizado a los municipios',
                    path:'municipios-auditorias',
                    icono: 'Auditorias'
                },
                {
                    nombre: 'Informes anuales',
                    descripcion: 'Informes de actividades anuales',
                    path:'municipios-informes-anuales',
                    icono: 'Informe-Anual'
                },
                {
                    nombre: 'Renuncias',
                    descripcion: 'Número de renuncias definitivas',
                    path:'municipios-renuncias',
                    icono: 'Renuncias'
                },
                {
                    nombre: 'Postulaciones a otros cargos',
                    descripcion: 'Autoridades municipales postuladas/designadas a otros cargos',
                    path:'municipios-postulaciones',
                    icono: 'Postulaciones'
                },
                {
                    nombre: 'Permisos / licencias',
                    descripcion: 'Número de permisos y/o licencias en los municipios',
                    path:'municipios-licencias-permisos',
                    icono: 'Licencias'
                },
                {
                    nombre: 'Nombramientos Cabildo',
                    descripcion: 'Nombramientos a cargo del cabildo municipal',
                    path:'municipios-nombramientos-cabildo',
                    icono: 'Nombramientos-Cabildo'
                },
                 {
                    nombre: 'Violencia Política en Razón de Género',
                    descripcion: 'Número de denuncias presentadas por Violencia Política en Razón de Género',
                    path:'municipios-violencia-razon-genero',
                    icono: 'Violencia-Genero'
                },


            ]
        }
    },
    methods:{
        goIndicadores( data ) {
             this.$router.push( { name: `${ data }`, params: { municipio: this.$router.currentRoute._value.params.municipio }})
        },
        goMenu() {
            this.$router.push({ name: 'home-municipios' })
        }
    }
}
</script>

<style lang="scss" scoped>
.indicadores-contenedor{
    width:96%;
    margin:20px auto;
    & h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform:uppercase;
    }
}

    .contenedor-indicadores{
        min-width:980px;
        margin:10px auto;
        display:flex; flex-wrap: wrap; align-items:center; justify-content: center;
    }
    .ficha-indicador{
        width:23%;
        height:250px;
        margin:10px 10px;
        background-color: #fff;
        border-radius:10px;
        padding:10px 12px;
        text-align:center;
    }
   .ficha-titulo{
        display:flex; align-items: center; justify-content: space-between;
    }
    .ficha-titulo h3{
        font-size:14px;
        text-align:left;
        width:50%;
        margin:30px auto;
        cursor:pointer;
        font-weight: bold;
    }
    .ficha-titulo h3:hover{
        text-decoration:underline;
    }
    
    .ficha-descripcion{
        width:90%;
        margin:0 auto;
        height: 80px;
        & p{
            font-size:12px;
            text-align:left;
        }
    }
    .contenedor-logo{
        width:40%;
        display:flex; align-items:center; justify-content:center;
        margin:20px auto;

    }
   

</style>