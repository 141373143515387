<template>
  <div class="indicadores-contenedor">

      <div class="item-flex-between">
        <h1>Proceso electoral</h1>
        <span
            @click="goMenu"
        >
            X
        </span>
      </div>
    

      <div class="contenedor-indicadores">
          <div class="ficha-indicador" v-for="(elemento, index) in indicadores" :key="index">
                <div class="ficha-body">
                    <div class="ficha-titulo">
                            <h3>{{elemento.nombre}}</h3>
                             <div class="contenedor-logo">
                                <Iconos :nombre= "elemento.icono" />
                            </div>
                    </div>
                    <div class="ficha-descripcion">
                        <p>{{elemento.descripcion}}</p>
                    </div>
                </div>
                <button
                    @click="goIndicador( elemento.path )" 
                    class="btn-consulta">
                        Consultar
                    </button>

          </div>
      </div>
  </div>
</template>

<script>
import Iconos from '../components/iconos/diputaciones/procesoElectoral.vue'
export default {
    components: {
        Iconos
    },
    data() {
        return {
           indicadores: [
                {
                    nombre: 'Universo de aspirantes',
                    descripcion: 'Proporción de mujeres en el universo de potenciales aspirantes',
                    id: 1,
                    path: 'dip-universo-aspirantes',
                    icono: 'Universo-Aspirantes'
                },
                {
                    nombre: 'Lista Nominal',
                    descripcion: 'Proporción de mujeres que se encuentran inscritas en la lista nominal de electores',
                    id: 2,
                    path: 'dip-lista-nominal',
                    icono: 'Lista-Nominal'
                },
                {
                    nombre: 'Postulaciones Internas',
                    descripcion: 'Proporción de mujeres postuladas inicialmente por sectores internos partidiarios',
                    id: 3,
                    path: 'dip-postulaciones-internas',
                    icono: 'Postulaciones-Internas'
                },
                {
                    nombre: 'Candidaturas',
                    descripcion: 'Proporción de mujeres candidadas resultantes de elecciones internas partidiarias',
                    id: 4,
                    path: 'dip-candidaturas',
                    icono: 'Candidaturas'
                },
                {
                    nombre: 'Bloques de Competitividad',
                    descripcion: 'Diferencias proporcionales entre bloques de competitividad',
                    id: 5,
                    path: 'dip-bloques-competitividad',
                    icono: 'Bloque-Competitividad'
                },
                {
                    nombre: 'Diputadas y Diputados Electos',
                    descripcion: 'Proporción de mujeres electas',
                    id: 6,
                    path: 'dip-diputaciones-electas',
                    icono: 'Diputaciones-Electas'
                },
                {
                    nombre: 'Titulares y Suplentes',
                    descripcion: 'Proporción entre candidaturas electas como titulares y como suplentes',
                    id: 7,
                    path: 'dip-titulares-suplentes',
                    icono: 'Titulares-Suplentes'
                },
                {
                    nombre: 'Distritos de mayor población',
                    descripcion: 'Proporción de mujeres y hombres en distritos de mayor población',
                    id: 8,
                    path: 'dip-distritos-mayor-poblacion',
                    icono: 'Distritos-Mayor'
                },
                


            ] 
        }
    },
    methods: {
        goIndicador( data ){
            this.$router.push( { name: `${ data }` })
        },
        goMenu() {
            this.$router.push({ name: 'dip-menu-indicadores' })
        }
    }
}
</script>

<style lang="scss">
.indicadores-contenedor{
    width:96%;
    margin:20px auto;
    & h1 {
        font-size: 18px;
        font-weight: bold;
        text-transform:uppercase;
    }
}

    .contenedor-indicadores{
        min-width:980px;
        margin:10px auto;
        display:flex; flex-wrap: wrap; align-items:center; justify-content: center;
    }
    .ficha-indicador{
        width:23%;
        height:250px;
        margin:10px 10px;
        background-color: #fff;
        border-radius:10px;
        padding:10px 12px;
        text-align:center;
    }
    .ficha-titulo{
        display:flex; align-items: center; justify-content: space-between;
    }
    .ficha-titulo h3{
        font-size:14px;
        text-align:left;
        width:50%;
        margin:30px auto;
        cursor:pointer;
        font-weight: bold;
    }
    .ficha-titulo h3:hover{
        text-decoration:underline;
    }
    
    .ficha-descripcion{
        width:90%;
        margin:0 auto;
        height: 80px;
        & p{
            font-size:12px;
            text-align:left;
        }
    }
    .contenedor-logo{
        width:40%;
        display:flex; align-items:center; justify-content:center;
        margin:20px auto;

    }
    

</style>




