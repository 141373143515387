<template>
  <div class="indicador-view-contenedor">
      <div class="ficha-indicador">
          <Nav/>
          <Titulo
            :indicador="indicador"
            :periodo="periodo"
        />
        <div class="municipios-mayor-poblacion">
            <line-chart 
            :data="diputaciones"
            :colors="['#AA9AAD','#583177']"
            :legend="true"
            />
        </div>
          
      </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Nav: defineAsyncComponent( () => import('../../components/Nav.vue')),
        Titulo: defineAsyncComponent( () => import('../../components/ficha-titulo.vue')),
        NoPage: defineAsyncComponent( () => import('../../components/no-informacion.vue'))
    },
     data() {
        return {
            indicador: 'Distritos mayor población',
            periodo: '2021',
             diputaciones: [
                { name: 'Hombres', data : 
                    { 
                        Distrito1: 550, 
                        'Distrito 2': 100,
                        'Distrito 3': 400,
                        'Distrito 4': 400,
                        'Distrito 5': 400,
                        'Distrito 6': 400,
                        'Distrito 7': 400,
                        'Distrito 8': 400,
                        'Distrito 9': 400,
                        'Distrito 10': 400,
                        'Distrito 11': 400,
                        'Distrito 12': 400
                    } 
                },
                {
                    name: 'Mujeres', data: 
                    {
                        Distrito1: 200,
                        'Distrito 2': 100,
                        'Distrito 3': 400,
                        'Distrito 4': 400,
                        'Distrito 5': 400,
                        'Distrito 6': 400,
                        'Distrito 7': 400,
                        'Distrito 8': 400,
                        'Distrito 9': 400,
                        'Distrito 10': 400,
                        'Distrito 11': 400,
                        'Distrito 12': 400
                    }
                }
                
            ]
        }
    }
}
</script>

<style lang="scss" scoped>

    .indicador-view-contenedor{
        width:70%;
        margin:0 auto;
        height:calc(100vh - 60px);
        background-color: #fff;
        display:flex; align-items:center; justify-content:center;

        .ficha-indicador{
            width:94%;
            margin:10px auto;
            height:calc(100vh - 120px);
            background-color: #f2f2f2;

            .municipios-mayor-poblacion {
                width:90%;
                height: calc(100vh - 270px);
                margin:10px auto;
                display: flex; align-items:center; justify-content:center; 
                
            }
        }
    }

</style>